@import url(https://fonts.googleapis.com/css?family=Raleway:900);
body,html,#root{width:100%;margin:0;padding:0;overflow-x:hidden}body,html{scroll-behavior:smooth}

@-webkit-keyframes blink{0%{opacity:.2}20%{opacity:1}100%{opacity:.2}}@keyframes blink{0%{opacity:.2}20%{opacity:1}100%{opacity:.2}}.saving span{-webkit-animation-name:blink;animation-name:blink;-webkit-animation-duration:1.4s;animation-duration:1.4s;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-fill-mode:both;animation-fill-mode:both}.saving span:nth-child(2){-webkit-animation-delay:.2s;animation-delay:.2s}.saving span:nth-child(3){-webkit-animation-delay:.4s;animation-delay:.4s}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face{font-family:"Questa Regular";src:url(/static/media/Questa-Regular.77407b18.eot);src:url(/static/media/Questa-Regular.77407b18.eot) format("embedded-opentype"),url(/static/media/Questa-Regular.f94f954c.svg) format("svg"),url(/static/media/Questa-Regular.45a20356.ttf) format("truetype"),url(/static/media/Questa-Regular.b058ab04.woff) format("woff"),url(/static/media/Questa-Regular.8b8a2a5e.woff2) format("woff2");font-weight:normal;font-style:normal}@font-face{font-family:"Crimson-Roman";src:url(/static/media/Crimson-Roman.704d56d5.eot);src:url(/static/media/Crimson-Roman.704d56d5.eot) format("embedded-opentype"),url(/static/media/Crimson-Roman.299ce5fb.svg) format("svg"),url(/static/media/Crimson-Roman.76207179.ttf) format("truetype"),url(/static/media/Crimson-Roman.6057a702.woff) format("woff"),url(/static/media/Crimson-Roman.1178b7dc.woff2) format("woff2");font-weight:normal;font-style:normal}

