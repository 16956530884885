body,
html,
#root {
  width: 100%;
  // height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
body,
html {
  // overflow: hidden;
  scroll-behavior: smooth;
}